import { get } from 'lodash';
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, ErrorBoundary, Input, Link, Popup, Row, Tab, TabBar, Theme, themeOptions, Toggle, ToggleLink } from '@commonsku/styles';

import { closePopup } from '../actions/popup';
import {
  createAddItemColor,
  createAddItemSize,
  createAddItemSku,
  createDeleteItemColor,
  createDeleteItemSize,
  createDeleteItemSku,
  createDeleteAllItemColors,
  createDeleteAllItemSizes,
  createDeleteAllItemSkus,
  createFetchInventoryLevel
} from '../actions';

import { getFullOptionItem } from '../selectors';
import { BASE_ZINDEX } from '../popup-factory';
import { sizeSort } from '../utils';
import { ProductOption } from './SelectPSSkuByColorSize';
import { createGlobalStyle } from './helpers';
import CheckInventoryLevelTable from './CheckInventoryLevelTable';

export const GlobalStyle = createGlobalStyle();

class SkuAxesSubsetPopup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      color_list: this.props.colors.variant_list,
      selected_colors: this.props.colors.selected,

      size_list: this.props.sizes.variant_list,
      selected_sizes: this.props.sizes.selected,

      sku_list: this.props.skus.variant_list,
      sku_skus: this.props.skus.selected,

      selectedTabIndex: this.props.selectedTabIndex || (this.props.params === 'size' ? 1 : 0),
      item_levels: get(this.props, ['item', 'levels']) ? this.props.item.levels.results : null,
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.props.api_name || this.props.sku || !this.props.item_id) {
      return;
    }
    this.props.onCreateFetchInventoryLevel(this.props.sku, this.props.api_name, this.props.item_id);
  }

  getSubsetAxis(axis, state, props, nextProps) {
    const axes = state[`${axis}_list`].map(v => v[props[`${axis}s`].variant_id_name]);
    const item_axis_id_by_axis_id = nextProps[`${axis}s`].variant_list.reduce((o, v) => {
      o[v[props[`${axis}s`].variant_id_name]] = v[props[`${axis}s`].item_variant_id_name];
      return o;
    }, {});
    const checked_by_axis_id = nextProps[`${axis}s`].variant_list.reduce((o, v) => {
      o[v[props[`${axis}s`].variant_id_name]] = v.checked;
      return o;
    }, {});

    return {
      [`${axis}_list`]: state[`${axis}_list`].concat(
        nextProps[`${axis}s`].variant_list.filter(v => !axes.includes(v[props[`${axis}s`].variant_id_name]))
      ).map(v => Object.assign({}, v, {
        checked: checked_by_axis_id[v[props[`${axis}s`].variant_id_name]] || false,
        [props[`${axis}s`].item_variant_id_name]: item_axis_id_by_axis_id[v[props[`${axis}s`].variant_id_name]]
      })),
      [`selected_${axis}s`]: nextProps[`${axis}s`].selected,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      loading: false,
      item_levels: get(nextProps, ['item', 'levels']) ? nextProps.item.levels.results : null,
      ...this.getSubsetAxis('color', this.state, this.props, nextProps),
      ...this.getSubsetAxis('size', this.state, this.props, nextProps),
    });
  }

  getTab(tabs, tabIndex=0) {
    if (!tabs.length || tabs.length-1 < tabIndex) {
      return null;
    }
    return tabs[tabIndex];
  }

  render() {
    const { onClosePopup, index, item_id, textTransform, addNew, item } = this.props;
    const { item_levels, selectedTabIndex=0, } = this.state;
    const key = selectedTabIndex === 0 ? 'colors' : 'sizes';
    const variant_list = this.state[`${key.slice(0, -1)}_list`];
    const selected = this.state[`selected_${key}`];

    const variant_id_name = this.props[key].variant_id_name;
    const item_variant_id_name = this.props[key].item_variant_id_name;
    const variant_name = this.props[key].variant_name;

    const onAddItemVariant = selectedTabIndex === 0 ? this.props.onAddItemColor : this.props.onAddItemSize;
    const onDeleteItemVariant = selectedTabIndex === 0 ? this.props.onDeleteItemColor : this.props.onDeleteItemSize;
    const onDeleteAllItemVariants = selectedTabIndex === 0 ? this.props.onDeleteAllItemColor : this.props.onDeleteAllItemSize;

    const handleToggleVariant = (item_variant_id, variant_id) => checked => {
      if (1 === checked) {
        onAddItemVariant(item_id, variant_id);
      } else {
        onDeleteItemVariant(item_variant_id);
      }
    };
    const all_selected = variant_list.reduce((s, v) => s && selected.includes(v[variant_id_name]), true);
    const handleToggleAllSelected = checked => {
      if (1 === checked) {
        onAddItemVariant(item_id, variant_list.filter(v => !selected.includes(v[variant_id_name])).map(v => v[variant_id_name]));
      } else {
        onDeleteAllItemVariants(item_id);
      }
    };

    const tabs = [
      {
        variant: 'secondary',
        label: 'Colors',
        content: <AxisProductOptions
          item_id={item_id}
          variant_list={variant_list}
          variant_name={variant_name}
          variant_id_name={variant_id_name}
          item_variant_id_name={item_variant_id_name}
          selected={selected}
          handleToggleVariant={handleToggleVariant}
          onAddItemVariant={onAddItemVariant}
          onToggleAllSelected={handleToggleAllSelected}
        />,
      },
      {
        variant: 'secondary',
        label: 'Sizes',
        content: <AxisProductOptions
          item_id={item_id}
          variant_list={variant_list}
          variant_name={variant_name}
          variant_id_name={variant_id_name}
          item_variant_id_name={item_variant_id_name}
          selected={selected}
          handleToggleVariant={handleToggleVariant}
          onAddItemVariant={onAddItemVariant}
          onToggleAllSelected={handleToggleAllSelected}
        />,
      },
    ].concat(item_levels ? {
      variant: 'secondary',
      label: 'Inventory',
      content: <CheckInventoryLevelTable item_levels={item_levels} />,
    } : []);
    const selectedTab = this.getTab(tabs, selectedTabIndex);

    return (
      <Theme theme={themeOptions}>
        <GlobalStyle theme={themeOptions} />
        <ErrorBoundary>
          <Popup
            title={'Pick Variations'}
            onClose={this.handleClose}
            zIndex={BASE_ZINDEX + index}
            overlayZIndex={BASE_ZINDEX + index}
            controls={<Button variant={'cta'} onClick={onClosePopup}>Done</Button>}
          >
            <Row>
              <Col padded xs sm={8} md={9}>
                <TabBar padded={this.props.padded === true}>
                  {tabs.map((t, i) => (
                    <Tab
                      key={'tab-' + i}
                      variant={t.variant}
                      size={'medium'}
                      selected={i === selectedTabIndex}
                      onClick={() => {
                        this.setState({ selectedTabIndex: i });
                      }}
                    >{t.label}</Tab>
                  ))}
                </TabBar>
              </Col>
            </Row>
            <Col padded xs>
              {get(selectedTab, ['content'], '')}
            </Col>
          </Popup>
        </ErrorBoundary>
      </Theme>
    );
  }
}

const AxisProductOptions = React.memo((props) => {
  const {
    variant_list,
    variant_name,
    variant_id_name,
    item_variant_id_name,
    selected,
    item_id,
    handleToggleVariant,
    onAddItemVariant,
    onToggleAllSelected,
  } = props;

  const [newValue, setNewValue] = useState('');

  const onAddVariant = () => {
    if (newValue.length) {
      onAddItemVariant(item_id, null, newValue);
      setNewValue('');
    }
  };

  const onToggle = (v) => {
    const isSelected = selected.includes(v[variant_id_name]);
    handleToggleVariant(
      v[item_variant_id_name],
      v[variant_id_name]
    )(isSelected ? 0 : 1);
  };

  return (
    <Row>
      <Col padded xs sm={4}>
        <Input
          value={newValue}
          onChange={e => setNewValue(e.target.value)}
          onKeyUp={e => {
            if (13 === e.keyCode) { onAddVariant(); }
          }}
        />
      </Col>
      <Col padded xs sm={2}>
        <Button size='medium' variant='primary' onClick={onAddVariant}>Add</Button>
      </Col>
      <Col padded xs sm={6} smStyle={{ textAlign: 'right', alignSelf: 'center' }}>
        <Link
          style={{ padding: 10, }}
          onClick={() => onToggleAllSelected(1)}
        >Select all</Link>
        <Link
          style={{ padding: 10, }}
          onClick={() => onToggleAllSelected(0)}
        >Select none</Link>
      </Col>
      {variant_list.length ? variant_list.map((v, i) =>
        <Col key={'product-option-'+i} padded xs sm={4}>
          <ProductOption
            label={v[variant_name] + (v.on_product ? '' : ' *')}
            isSelected={selected.includes(v[variant_id_name])}
            onToggle={() => onToggle(v)}
          />
        </Col>
      ) : <Col xs>No Variation Available</Col>}
    </Row>
  );
});

const getColorProps = (state, ownProps) => {
  const item = getFullOptionItem(state, { item_id: ownProps.item_id });
  const item_colors = (item.item_colors || []).map(c => c.color_id);
  const product_colors = (state.dropdowns.product_colors[item.parent_id] || []);
  const product_color_list = Array.from(new Set(item_colors.concat(product_colors).filter(c => c != 'TBD'))).map(
    c => state.entities.colors[c]
  ).sort((a, b) => a.color_name < b.color_name ? -1 : (a.color_name > b.color_name ? 1 : 0)).map(
    c => Object.assign({}, c, {
      checked: item_colors.includes(c.color_id),
      on_product: product_colors.includes(c.color_id),
      item_color_id: item_colors.includes(c.color_id) ? item.item_colors.filter(ic => ic.color_id === c.color_id)[0].item_color_id : null
    })
  );
  return {
    item,
    variant_list: product_color_list,
    selected: item_colors,
    variant_id_name: 'color_id',
    variant_name: 'color_name',
    item_variant_id_name: 'item_color_id',
    textTransform: 'capitalize',
    addNew: true
  };
};

const getSizeProps = (state, ownProps) => {
  const item = getFullOptionItem(state, { item_id: ownProps.item_id });
  const item_sizes = (item.item_sizes || []).map(c => c.size_id);
  const product_sizes = (state.dropdowns.product_sizes[item.parent_id] || []);
  const product_size_list = Array.from(new Set(item_sizes.concat(product_sizes).filter(c => c != 'TBD'))).map(
    c => state.entities.sizes[c]
  ).filter(c => c).map(
    c => Object.assign({}, c, {
      checked: item_sizes.includes(c.size_id),
      on_product: product_sizes.includes(c.size_id),
      item_size_id: item_sizes.includes(c.size_id) ? item.item_sizes.filter(ic => ic.size_id === c.size_id)[0].item_size_id : null
    })
  ).sort(
    (a, b) => sizeSort(a.size_name, b.size_name)
  );
  return {
    item,
    variant_list: product_size_list,
    selected: item_sizes,
    variant_id_name: 'size_id',
    variant_name: 'size_name',
    item_variant_id_name: 'item_size_id',
    textTransform: 'uppercase',
    addNew: true
  };
};

const getSkuProps = (state, ownProps) => {
  const item = getFullOptionItem(state, { item_id: ownProps.item_id });
  const item_skus = (item.item_skus || []).map(c => c.product_sku_id);
  const product_skus = (state.dropdowns.product_skus[item.parent_id] || []);
  const product_sku_list = Array.from(new Set(item_skus.concat(product_skus).filter(c => c != 'TBD'))).map(
    c => state.entities.skus[c]
  ).filter(c => c).sort((a, b) => (a.description + a.sku_description) < (b.description + b.sku_description) ? -1 : ((a.description + a.sku_description) > (b.description + b.sku_description) ? 1 : 0)).map(
    c => Object.assign({}, c, {
      label: !!c.description ? (c.description + (!!c.sku_description ? (' (' + c.sku_description + ')') : '')) : c.sku_description,
      checked: item_skus.includes(c.product_sku_id),
      on_product: product_skus.includes(c.product_sku_id),
      item_sku_id: item_skus.includes(c.product_sku_id) ? item.item_skus.filter(ic => ic.product_sku_id === c.product_sku_id)[0].item_sku_id : null
    })
  );
  return {
    item,
    variant_list: product_sku_list,
    selected: item_skus,
    variant_id_name: 'product_sku_id',
    variant_name: 'label',
    item_variant_id_name: 'item_sku_id',
    addNew: false
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    item: getFullOptionItem(state, { item_id: ownProps.item_id }),
    colors: getColorProps(state, ownProps),
    sizes: getSizeProps(state, ownProps),
    skus: getSkuProps(state, ownProps),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClosePopup: () => {
      dispatch(closePopup());
    },
    onCreateFetchInventoryLevel: (sku, api_name, item_id) => {
      dispatch(createFetchInventoryLevel(sku, api_name, item_id));
    },
    onAddItemColor: (item_id, color_id, color_name) => dispatch(createAddItemColor(item_id, color_id, color_name)),
    onDeleteItemColor: item_color_id => dispatch(createDeleteItemColor(item_color_id)),
    onDeleteAllItemColor: item_id => dispatch(createDeleteAllItemColors(item_id)),
    onAddItemSize: (item_id, size_id, size_name) => dispatch(createAddItemSize(item_id, size_id, size_name)),
    onDeleteItemSize: item_size_id => dispatch(createDeleteItemSize(item_size_id)),
    onDeleteAllItemSize: item_id => dispatch(createDeleteAllItemSizes(item_id)),
    onAddItemSku: (item_id, sku_id) => dispatch(createAddItemSku(item_id, sku_id)),
    onDeleteItemSku: item_sku_id => dispatch(createDeleteItemSku(item_sku_id)),
    onDeleteAllItemSku: item_id => dispatch(createDeleteAllItemSkus(item_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkuAxesSubsetPopup);
