import React from 'react';
import { AlertIcon, colors, Table, TBody, TD, TH, THead, TR } from '@commonsku/styles';
import { toInteger } from 'lodash';

export default function CheckInventoryLevelTable(props) {
  const { item_levels, } = props;
  if (!item_levels) {
    return null;
  }
  return (
    <Table>
      <THead style={{ border: 'none' }}>
        <TR>
          <TH bg='white' colSpan="2">SKU</TH>
          <TH bg='white' colSpan="4">Description</TH>
          <TH bg='white' colSpan="2">Color</TH>
          <TH bg='white' colSpan="1">Size</TH>
          <TH bg='white' colSpan="1">Quantity Available</TH>
        </TR>
      </THead>
      <TBody style={{ border: 'none' }}>
        {item_levels ? item_levels.map(l => {
          const isChecking = ('' + (l.quantity_available || '')).includes('Checking');
          const qty = toInteger(l.quantity_available);
          return (
            <TR key={l.part_id}>
              <TD colSpan="2" style={{ color: '#000' }}>{l.part_id}</TD>
              <TD colSpan="4" style={{ color: '#000' }}>{l.description}</TD>
              <TD colSpan="2" style={{ color: '#000' }}>{l.color}</TD>
              <TD colSpan="1" style={{ color: '#000' }}>{l.size}</TD>
              <TD colSpan="1" style={{ color: qty === 0 && !isChecking ? colors.yellow[60] : '#000' }}>
                {qty === 0 && !isChecking &&
                  <AlertIcon
                    filled
                    color={colors.yellow[60]}
                    style={{ verticalAlign: 'middle', paddingRight: 5, }}
                  />}
                <span style={{ fontSize: 14, }}>{isChecking ? l.quantity_available : qty}</span>
              </TD>
            </TR>
          );
        }) : null}
      </TBody>
    </Table>
  );
}
