import React from 'react';
import { connect } from 'react-redux';
import { get, groupBy, map, } from 'lodash';
import {
  LabeledCheckbox,
  Col, Row,
  Csku, Text,
  fontFamilies, colors, Link, TabBar, Tab,
} from '@commonsku/styles';
import { createFetchInventoryLevel } from '../actions';
import { getFullItem } from '../selectors';
import { SelectSku } from './SelectSku';
import CheckInventoryLevelTable from './CheckInventoryLevelTable';
import { createFetchProduct } from '../actions/product';
import ProductPricesByCategories from './select-sku/ProductPricesByCategories';

class SelectPSSkuByColorSize extends SelectSku {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      selectedTabIndex: props.selectTabIndex || 0,
    };
  }

  getTab(tabs, tabIndex = 0) {
    if (!tabs.length || tabs.length - 1 < tabIndex) {
      return null;
    }
    return tabs[tabIndex];
  }

  render() {
    const { value, skus, selectedTabIndex, } = this.state;
    const data = this.getParsedData();
    const parts = get(data, ['parts']) || [];
    const costCategories = get(data, ['costCategories']) || [];
    const item_levels = map(
      parts,
      v => ({
        part_id: v.sku,
        description: v.description,
        color: v.color,
        size: v.size,
        quantity_available: v.inventory.split(' ')[0].trim(),
      })
    );

    const selectedSkus = !!this.props.onSkusSelected ? value : [];
    const onToggleSkus = (SKUs) => SKUs.length > 0 && this.toggleValue(SKUs);

    const getPartsByAxis = (axis) => groupBy(parts, axis);
    const colors = getPartsByAxis('color');
    const sizes = getPartsByAxis('size');
    const selectedColors = Object.keys(colors)
      .filter(k => colors[k].filter(v => selectedSkus.includes(v.sku)).length > 0);
    const selectedSizes = Object.keys(sizes)
      .filter(k => sizes[k].filter(v => selectedSkus.includes(v.sku)).length > 0);
    const onToggleAll = (isChecked) => {
      let selected = selectedColors;
      let currentParts = colors;
      if (selectedTabIndex === 1) {
        selected = selectedSizes;
        currentParts = sizes;
      }
      const currentSkus = Object.keys(currentParts);
      if ((isChecked && selected.length < currentSkus.length)
        || (!isChecked && selected.length > 0)
      ) {
        this.toggleValue(
          currentSkus
            .filter(k => isChecked ? !selected.includes(k) : selected.includes(k))
            .reduce((acc, k) => ([
              ...acc,
              ...currentParts[k]
                .filter(v => !isChecked ? selectedSkus.includes(v.sku) : true)
                .map(v => v.sku),
            ]), [])
        );
      }
    };
    const handleSelectCost = (op) => {
      this.props.onSelectCost(op.sku);
    };

    const tabs = [
      {
        variant: 'secondary',
        label: 'Colors',
        content: <ProductAxisOptions
          axis='color'
          axes={data.axes}
          parts={parts}
          skus={skus}
          selectedSkus={selectedSkus}
          onToggleSkus={onToggleSkus}
        />,
      },
      {
        variant: 'secondary',
        label: 'Sizes',
        content: <ProductAxisOptions
          axis='size'
          axes={data.axes}
          parts={parts}
          skus={skus}
          selectedSkus={selectedSkus}
          onToggleSkus={onToggleSkus}
        />,
      },
      {
        variant: 'secondary',
        label: 'Inventory',
        content: <CheckInventoryLevelTable item_levels={item_levels} />,
      },
      {
        variant: 'secondary',
        label: 'Pricing',
        content: <ProductPricesByCategories
          costCategories={costCategories}
          onSelectCost={handleSelectCost}
          selectedSkus={this.props.selectedCostSku ? [this.props.selectedCostSku] : []}
          currency_id={this.props.currency_id}
          search_query={this.props.search_query}
        />,
      },
    ];
    const selectedTab = this.getTab(tabs, this.state.selectedTabIndex);

    return (
      <Row>
        <Col padded xs sm={8} md={9} className="tabbar">
          <TabBar padded={this.props.padded === true}>
            {tabs.map((t, i) => (
              <Tab
                key={'tab-' + i}
                variant={t.variant}
                size={'medium'}
                selected={i === selectedTabIndex}
                onClick={() => {
                  this.setState({ selectedTabIndex: i });
                }}
              >{t.label}</Tab>
            ))}
          </TabBar>
        </Col>
        <Col padded xs sm={4} md={3} smStyle={{ textAlign: 'right', alignSelf: 'center' }}>
          {(selectedTabIndex === 0 || selectedTabIndex === 1) && <>
            <Link style={{ padding: 10, }} onClick={() => onToggleAll(1)}>Select all</Link>
            <Link style={{ padding: 10, }} onClick={() => onToggleAll(0)}>Select none</Link>
          </>}
        </Col>
        <Col padded xs className='tabcontent'>
          {get(selectedTab, ['content'], '')}
        </Col>
      </Row>
    );
  }
}

function ProductAxisOptions(props) {
  const {
    axis,
    parts,
    skus,
    selectedSkus,
    onToggleSkus,
  } = props;
  const partsByAxis = React.useMemo(
    () => groupBy(parts, axis),
    [parts, axis]
  );

  return (
    <Row>
      <Col xs padded style={{ background: colors.neutrals[20], padding: 16, borderRadius: 5 }}>
        <Text style={{ color: colors.neutrals[90], fontFamily: fontFamilies.regular }}>
          Select product {axis}s you would like to show on your presentation. Not all {axis}s are available at all {axis === 'color' ? 'sizes' : 'colors'}.
        </Text>
      </Col>
      {Object.keys(partsByAxis).length === 0 ? <Col xs padded style={{ padding: 16, textAlign: 'center' }}>
        <Text style={{ color: colors.neutrals[100], fontFamily: fontFamilies.regular }}>No Variations Available</Text>
      </Col> : null}
      {Object.keys(partsByAxis).map((k, i) => {
        const label = k || `Unknown ${axis.charAt(0).toUpperCase() + axis.slice(1)}`;
        const parts = partsByAxis[k] || [];
        const partSkus = parts.map(p => p.sku);
        const partsSelected = partSkus.filter(p => selectedSkus.includes(p));
        const isSelected = partsSelected.length > 0;
        return (
          <Col key={`product-${axis}-option-${i}`} padded xs sm={4}>
            {label !== 'undefined' && label !== 'null' && <ProductOption
              label={label}
              isSelected={isSelected}
              onToggle={() => onToggleSkus(isSelected ? partsSelected : partSkus)}
            />}
          </Col>
        );
      })}
    </Row>
  );
}

export const ProductOption = (props) => {
  const {
    label,
    isSelected,
    onToggle,
  } = props;

  const Label = (
    <Text style={{
      color: colors.teal.main,
      fontFamily: fontFamilies.bold
    }}>{label}</Text>
  );

  return (
    <Csku onClick={onToggle} style={{
      ":hover": {
        background: `${colors.white} !important`,
        borderColor: `${colors.teal.main} !important`,
      },
      background: isSelected ? colors.white : colors.teal[20],
      borderColor: isSelected ? colors.teal.main : colors.teal[20],
      borderRadius: 2000,
      padding: 10,
      paddingLeft: 14,
      paddingTop: 14,
      cursor: 'pointer',
      borderWidth: 3,
      borderStyle: 'solid',
    }}>
      <LabeledCheckbox
        checked={isSelected}
        onChange={onPreventDefault}
        onClick={onPreventDefault}
        label={Label}
        labelStyle={{ marginBottom: 0, marginRight: 0, }}
      />
    </Csku>
  );
};

function onPreventDefault(e) {
  e && e.stopPropagation();
  e && e.preventDefault();
}

const mapStateToProps = (state, ownProps) => ({
  product: ownProps.product || state.entities.products[ownProps.product_id],
  item: getFullItem(state, { item_id: ownProps.item_id }),
  canToggleValueOnFetch: false,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadProduct: () => dispatch(createFetchProduct(ownProps.product_id)),
  onCreateFetchInventoryLevel: (sku, api_name, item_id) => {
    dispatch(createFetchInventoryLevel(sku, api_name, item_id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectPSSkuByColorSize);
